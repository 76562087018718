@import "../../styles/_chunk";

.mystery-bundle-card {
  padding-top: 25px;
  padding-bottom: 20px;
  position: relative;

  .mystery-content-container {
    display: flex;
    flex-direction: column;
    background-color: rgba(black, 0.5);
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }

    .left-container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;

      @include media-breakpoint-up(lg) {
        width: 45%;
      }

      .responsive-image {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        @include media-breakpoint-up(lg) {
          border-top-right-radius: 0;
          border-bottom-left-radius: $border-radius;
        }

        img {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
          overflow: hidden;

          @include media-breakpoint-up(lg) {
            border-top-right-radius: 0;
            border-bottom-left-radius: $border-radius;
          }
        }
      }
    }

    .right-container {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 55%;
      }
    }
  }

  .cover-container {
    width: 100%;
  }

  .mystery-details-container {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 2rem;
    }

    .intro-container {
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
      display: none;

      @include media-breakpoint-up(md) {
        font-size: 0.9375rem;
        display: block;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }
    }

    .icons-container {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .system-icons {
        @include theme(light) {
          color: map-get($light-theme, card-icons-color);
        }
      }
    }

    .drm-container {
      margin-right: 1rem;
    }
  }

  .slider-title {
    @include brand-property(color, brand-core);

    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.375rem;
    }
  }

  .slider-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .price-discount-container {
    display: flex;
    align-items: center;

    .price {
      @include brand-property(color, brand-core);

      font-weight: 700;
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    .discount {
      font-size: 1.125rem;
    }
  }

  .mystery-slider-container {
    margin-bottom: 1rem;
    width: 100%;
    flex-shrink: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      width: 70%;
    }

    @include media-breakpoint-up(lg) {
      width: 55%;
    }

    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    .rangeslider {
      width: 100%;
      height: 16px;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .rangeslider__handle-tooltip {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin: 0;
      }
    }
  }

  .button-container {
    width: 100%;
    flex-shrink: 0;

    @include media-breakpoint-up(md) {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      padding-left: 1rem;
    }

    @include media-breakpoint-up(lg) {
      width: 45%;
    }

    @include media-breakpoint-up(xl) {
      width: 40%;
    }

    .cart-btn {
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .cart-btn-icon {
        padding: 0.375rem 0.5rem;

        @include media-breakpoint-down(md) {
          font-size: 13px;
        }
      }

      .btn-text-container b {
        @include media-breakpoint-down(xl) {
          font-size: 16px;
        }
      }
    }
  }
}
