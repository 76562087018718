@import "../../styles/_chunk";

.MysteryEggCard {
  background-image: linear-gradient(to bottom, #21bdce, #02606e);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  margin-top: 2rem;
  overflow: hidden;
  padding: 1rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
    height: 340px;
  }

  @include media-breakpoint-up(xl) {
    height: 375px;
  }

  @include media-breakpoint-up(xxl) {
    height: 400px;
  }

  .MysteryEggCard__logoStrapline {
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
      flex-direction: column;
      margin-bottom: 0;
      padding: 0 1rem 0 0;
    }

    .MysteryEggCard__logo {
      flex-shrink: 0;
      width: 240px;
      height: 67px;
      margin-bottom: 0.75rem;

      @include media-breakpoint-up(sm) {
        width: 280px;
        height: 79px;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 340px;
        height: 96px;
        margin-bottom: 0.5rem;
      }

      @include media-breakpoint-up(xl) {
        width: 420px;
        height: 118px;
        margin-bottom: 0.75rem;
      }

      @include media-breakpoint-up(xxl) {
        width: 480px;
        height: 150px;
        margin-bottom: 1rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .MysteryEggCard__strapline {
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      color: $white;
      font-size: 0.9375rem;

      @include media-breakpoint-up(sm) {
        font-size: 1.125rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 1rem;
        margin-left: 1.5rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
        margin: 0;
        padding: 0 0.5rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
        padding: 0 0.75rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.375rem;
        padding: 0 1rem;
      }
    }
  }

  .MysteryEggCard__purchaseBlock {
    background-image: linear-gradient(to right, rgba($black, 0.15), rgba($black, 0.05));
    border-radius: $border-radius;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    .MysteryEggCard__eggs {
      width: 240px;
      margin-bottom: -20px;

      @include media-breakpoint-up(sm) {
        width: 320px;
        margin-bottom: -30px;
      }

      @include media-breakpoint-up(md) {
        width: 360px;
        margin-bottom: -40px;
      }

      @include media-breakpoint-up(xl) {
        width: 410px;
        margin-bottom: -50px;
      }

      @include media-breakpoint-up(xxl) {
        width: 460px;
        margin-bottom: -60px;
      }
    }

    .MysteryEggCard__selectButtons {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
      }

      .MysteryEggCard__selectButton {
        margin: 0 0.25rem;
        border: 2px solid $white;
        background-color: transparent;
        border-radius: $border-radius-sm;
        min-width: 44px;
        min-height: 40px;
        color: $white;
        font-weight: 700;
        transition: all ease 0.5s;
        font-size: 14px;

        @include media-breakpoint-up(sm) {
          margin: 0 0.375rem;
          min-width: 60px;
          min-height: 36px;
        }

        @include media-breakpoint-up(md) {
          margin: 0 0.5rem;
          min-width: 70px;
          min-height: 36px;
        }

        @include media-breakpoint-up(xl) {
          margin: 0 0.5rem;
          min-width: 80px;
          min-height: 36px;
          font-size: 15px;
        }

        &:hover {
          background-color: $white;
          color: $text-black;
        }

        &--active {
          background-color: $white;
          color: $text-black;
        }
      }
    }

    .MysteryEggCard__priceBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      .MysteryEggCard__price {
        text-transform: uppercase;
        color: $white;
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 1rem;

        @include media-breakpoint-up(sm) {
          font-size: 1.125rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
          margin-bottom: 0;
          margin-right: 1rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1rem;
          margin-right: 1rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 1.25rem;
          margin-right: 1rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1.375rem;
        }
      }
    }
  }

  .MysteryEggCard__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left bottom;
    }
  }
}
