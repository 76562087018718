/**
* Rangeslider
*/

@import "../../styles/_chunk";

.rangeslider {
  margin: 20px 0;
  position: relative;
  background: $grey-4;
  -ms-touch-action: none;
  touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
}

.rangeslider .rangeslider__handle {
  background: $white;
  border: 1px solid $grey-4;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.25);

  &:focus {
    outline: none;
  }
}

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 60px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: $grey-2;
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-black;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
  display: inline-block;
  line-height: 100%;
}

.rangeslider .rangeslider__handle-tooltip::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}

/**
* Rangeslider - Horizontal slider
*/

.rangeslider-horizontal {
  height: 20px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.rangeslider-horizontal .rangeslider__fill {
  @include brand-property(background-color, brand-core);

  height: 100%;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle::after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: $grey-3;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid $grey-2;
  left: 50%;
  bottom: -7px;
  transform: translate3d(-50%, 0, 0);
}

/**
* Rangeslider - Vertical slider
*/

.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  @include brand-property(background-color, brand-core);

  width: 100%;
  box-shadow: none;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-vertical .rangeslider__handle-tooltip::after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

/**
* Rangeslider - Reverse
*/

.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

/**
* Rangeslider - Labels
*/

.rangeslider__labels {
  position: relative;
}

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: '';
  width: 10px;
  height: 2px;
  background: $black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  font-weight: 400;
  transform: translate3d(-50%, 0, 0);
}
